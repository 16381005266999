import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { connect } from "react-redux";

import { Grid2 as Grid } from "@mui/material";

import EsimActivationHeader from "../widgets/header";
import EsimIntroductionVideo from "../widgets/introduction";
import EsimActiveQR from "../widgets/active-qr";
import EsimInactiveQR from "../widgets/inactive-qr";
import EsimSecondVideo from "../widgets/second-video";
import EsimActivationFooter from "../widgets/footer";
import EsimLoader from "../widgets/loader";

import { getLandingEsimAction } from "_models/redux/get-landing-esim/action";
import { cleanGetLandingEsimAction } from "_models/redux/get-landing-esim/clean";

const QRContent = (props) => {
    const navigate = useNavigate();

    const { getLandingEsim, getLandingEsimAction } = props || {};

    const { loaded, payload } = getLandingEsim || {};

    const search = useLocation().search;
    const searchParams = new URLSearchParams(search);

    const uuid = searchParams.get("uuid");
    const order = searchParams.get("order");

    const [activated, setActivated] = useState("none");

    useEffect(() => {
        if (uuid && order) {
            getLandingEsimAction({
                uuid,
                order,
            });
        }

        return () => {};
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (loaded) {
            setActivated(true);

            if (!payload?.order_number) {
                navigate("/pagina-no-encontrada");

                navigate(`/pagina-no-encontrada`, {
                    state: {
                        payload,
                    },
                });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getLandingEsim]);

    return (
        <Grid container className="qr-activation-container">
            {!payload?.order_number ? (
                <Grid size={12}>
                    <EsimActivationHeader esimDetails={payload} />
                    <EsimIntroductionVideo />
                    <Grid container className="bg-container">
                        {activated === "none" ? (
                            <EsimLoader />
                        ) : activated ? (
                            <EsimActiveQR esimDetails={payload} />
                        ) : (
                            <EsimInactiveQR esimDetails={payload} />
                        )}
                        <EsimSecondVideo />
                    </Grid>
                    <EsimActivationFooter />
                </Grid>
            ) : uuid && order ? (
                <EsimLoader />
            ) : (
                <Grid size={12} sx={{ textAlign: "center" }}>
                    <h1>No se encontró la página</h1>
                </Grid>
            )}
        </Grid>
    );
};

const mapStateToProps = (state) => ({ getLandingEsim: state?.getLandingEsim });

const mapDispatchToProps = {
    getLandingEsimAction,
    cleanGetLandingEsimAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(QRContent);
